// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]


  acceptDataFromForm(data) {
  }

  sendApptDataToRailsController() {
    $.ajax({
      type: "POST",
      url: '/stimulus_post',
      data: "dan"
    })
  }

  setAppointment(event) {
    var selectedButtons = document.getElementsByClassName('selected');
    while(selectedButtons.length > 0){
      selectedButtons[0].classList.remove('selected');
    }
    event.currentTarget.classList.add('selected')
    var chosenTime = event.currentTarget.dataset.time
    document.getElementById("setAppointmentTime").value = chosenTime;
    // document.getElementById("showAppointmentTime").innerHTML = chosenTime;
    event.currentTarget.classList.add('selected')
    document.getElementById("modal-footer-container").style.display = "block";
    document.getElementById("confirmationButton").style.display = "block";
    this.sendApptDataToRailsController()
  }

  setAppointmentDay(event) {
    var selectedButtons = document.getElementsByClassName('selected');
    while(selectedButtons.length > 0){
      selectedButtons[0].classList.remove('selected');
    }

    event.currentTarget.classList.add('selected')
    // event.currentTarget.setAttribute("id", "selected")
    var chosenDay = event.currentTarget.dataset.day
    document.getElementById("setAppointmentDay").value = chosenDay;
    // document.getElementById("showAppointmentDay").innerHTML = chosenDay;
    event.currentTarget.classList.add('selected')
    this.sendApptDataToRailsController()
  }

  setPromo(event) {
    // document.getElementById("surveyButton").style.display = "block"
  }

  scrollTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  showNervousQuestion(event) {
    this.scrollTop();
    document.getElementById("question1").style.display = "none"
    document.getElementById("question2").style.display = "block"
  }

  showStepThree(event) {
    this.scrollTop();
    var thing1 = document.getElementById("question2")
    thing1.style.display = "none"
    var thing2 = document.getElementById("question3")
    thing2.style.display = "block"
  }

  showStepFour(event) {
    this.scrollTop();
    var thing1 = document.getElementById("question3")
    thing1.style.display = "none"
    var thing2 = document.getElementById("question4")
    thing2.style.display = "block"
  }

  showQuestionOne(event) {
    var question1 = document.getElementById("question1")
    question1.style.display = "none"
    var question2 = document.getElementById("question2")
    question2.style.display = "block"
  }

  showQuestionTwo(event) {
    document.getElementById("surveyButtonTwo").style.display = "block"
  }

  showQuestionThree(event) {
    document.getElementById("surveyButtonThree").style.display = "block"
  }

  showRangeOfDays(event) {

    var apptType = document.getElementById("apptType").value

    if (apptType == "New Patient Exam & Cleaning") {
      document.getElementById("appointmentType").innerHTML = apptType
      document.getElementById("sortedHygienistsAvailability").style.display = "block";
      var flatpickrObject = document.getElementsByClassName("img-fluid-chooseFlatpickr")
      flatpickrObject[0].dataset.controller = "flatpickr"
    }

    else {
      document.getElementById("appointmentType").innerHTML = apptType
      document.getElementById("sortedDentistsAvailability").style.display = "block";
      var flatpickrObject = document.getElementsByClassName("img-fluid-chooseFlatpickr")
      flatpickrObject[0].dataset.controller = "flatpickrd"
    }

    var email = document.getElementById('email').value;
    var pattern =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var isEmailValid =  pattern.test(email);

    var phone = document.getElementById('phone').value;
    var pattern = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/
    var isPhoneValid =  pattern.test(phone);

    var firstName = document.getElementById('firstName').value;
    var lastName = document.getElementById('lastName').value;
    if (document.getElementById("apptType").value == "" || document.getElementById("firstName").value == "" || document.getElementById("lastName").value == "") {
      document.getElementById("validation-messages").style.display = "block";
      document.getElementById("validation-messages").innerHTML = "All fields are required.";
    }

    else if (isEmailValid == false) {
      document.getElementById("validation-messages").style.display = "block";
      document.getElementById("validation-messages").innerHTML = "Please provide a valid email: email@domain.com";
    }

    else if (firstName.length < 2) {
      document.getElementById("validation-messages").style.display = "block";
      document.getElementById("validation-messages").innerHTML = "Please provide a first name with more than 2 letters.";
    }

    else if (lastName.length < 2) {
      document.getElementById("validation-messages").style.display = "block";
      document.getElementById("validation-messages").innerHTML = "Please provide a last name with more than 2 letters.";
    }

    else if (document.getElementById("phone").value == "" || isPhoneValid == false) {
      document.getElementById("validation-messages").style.display = "block";
      document.getElementById("validation-messages").innerHTML = "Please provide a valid phone number with no spaces or dashes. (4161234567)";
    }

    else {
      document.getElementById("apptbox").style.display = "block"
      document.getElementById("apptInfo").style.display = "none"
      // document.getElementById("dayRange").style.display = "block"
      document.getElementById("validation-messages").style.display = "none";
    }

  }

  saveVisitor(event) {

    var email = document.getElementById('email').value;
    var pattern =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var isEmailValid =  pattern.test(email);

    var phone = document.getElementById('phone').value;
    var pattern = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/
    var isPhoneValid =  pattern.test(phone);

    var firstName = document.getElementById('firstName').value;
    var lastName = document.getElementById('lastName').value;
    if (isEmailValid == false) {
      document.getElementById("validation-messages").style.display = "block";
      document.getElementById("validation-messages").innerHTML = "Please provide a valid email: email@domain.com";
    }

    else if (firstName.length < 2) {
      document.getElementById("validation-messages").style.display = "block";
      document.getElementById("validation-messages").innerHTML = "Please provide a first name with more than 2 letters.";
    }

    else if (lastName.length < 2) {
      document.getElementById("validation-messages").style.display = "block";
      document.getElementById("validation-messages").innerHTML = "Please provide a last name with more than 2 letters.";
    }

    else if (document.getElementById("phone").value == "" || isPhoneValid == false) {
      document.getElementById("validation-messages").style.display = "block";
      document.getElementById("validation-messages").innerHTML = "Please provide a valid phone number with no spaces or dashes. (4161234567)";
    }

    else {
      // document.getElementById("apptbox").style.display = "block"
      // document.getElementById("apptInfo").style.display = "none"
      // document.getElementById("dayRange").style.display = "block"
      document.getElementById("validation-messages").style.display = "none";
      document.getElementById("saveVisitor").click();
    }

  }

  // Email Validation

  showRangeOfTimes(event) {
    if (document.getElementById("setAppointmentDay").value == "") {
      document.getElementById("validation-messages").style.display = "block";
      document.getElementById("validation-messages").innerHTML = "Please Select A Day";
    }

    else {
      document.getElementById("dayRange").style.display = "none"
      document.getElementById("timeRange").style.display = "block"
    }
  }

  clearModalInputs() {
    // $('#apptForm').trigger("reset");
    console.log("before")
    // $("#contact-modal").load(" #contact-modal > *");
    location.reload();
    return false;
    console.log("after")
  }

  clickOnModalBackdrop(event) {
    $('.modal-backdrop show').click(function() {
  alert('hohoho');
});
  }

  onSubmit(event) {
    }

    hideDayContainer(event) {
      document.getElementById("sortedDentistsAvailability").style.display = "none"
    }
  }
