// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]

    setRating(event) {

      var selectedButtons = document.getElementsByClassName('selected');

      while(selectedButtons.length > 0){
        selectedButtons[0].classList.remove('selected');
      }

      event.currentTarget.classList.add('selected')
      document.getElementById("setRatingButton").style.display = "block"

    }

    sendRating(event) {
      console.log("send")

      var rating = document.getElementsByClassName('selected')[0].dataset.rating;
      var appointment = event.currentTarget.dataset.appointment
      console.log(appointment)

      $.ajax({
        url: "/ratings",
        type: 'POST',
        data: {
          chosenRating: rating,
          appointment: appointment
        }
      })
    }

  }
