// ./controllers/flatpickr_controller.js
// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from 'stimulus-flatpickr'

// you can also import a translation file
import { French } from 'flatpickr/dist/l10n/fr.js'
import { english } from "flatpickr/dist/l10n/default.js";


// import a theme (could be in your main CSS entry too...)
// import 'flatpickr/dist/themes/dark.css'
import 'flatpickr/dist/themes/light.css'


// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {

  initialize() {
    var dates = document.getElementById("blockedDentistsDates").innerHTML
    var apptType = document.getElementById("apptType").value

    var dates = dates.split(' , ')
    var dates = dates[0]
    //
    var arr = [];
    for (var i = 0; i < dates.length; i++) {
      arr.push(dates[i].name);
    }

    // sets your language (you can also set some global setting for all time pickers)
    this.config = {
      locale: english,
      inline: true,
      disableMobile: true,
      disable: JSON.parse(dates),
      dateFormat: "Y-m-d",
      minDate: new Date(),
      onChange: function(selectedDates, dateStr, instance) {
    }
  }
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  change(selectedDates, dateStr, instance) {
    var visibleDentistDates = document.querySelectorAll("[id*=DentistsSelectedDay-]")
    var i = 0
    var l = visibleDentistDates.length

    for (i; i < l; i++) {
        visibleDentistDates[i].style.display = 'none';
    }

    var visibleHygienistDates = document.querySelectorAll("[id*=HygienistsSelectedDay-]")
    var i = 0
    var l = visibleHygienistDates.length

    for (i; i < l; i++) {
        visibleHygienistDates[i].style.display = 'none';
    }
    // document.getElementById("sortedDentistsAvailability").style.display = "none"
    var dates = document.getElementById("dates").innerHTML
    var selectedDay = selectedDates[0]
    var formatted_date = moment(dateStr).format("dddd, MMM DD");



    var selectedDentistDayBox = document.getElementById("DentistsSelectedDay-" + formatted_date);
    var selectedHygienistDayBox = document.getElementById("HygienistsSelectedDay-" + formatted_date);


    document.getElementById("setAppointmentDay").value = dateStr

    var selectedDay = selectedDates[0]
    var formatted_date = moment(dateStr).format("dddd, MMM DD, YYYY");


    //
    // document.getElementById("HygienistsSelectedDay-" + formatted_date).style.display = "block";
    // document.getElementById("DentistsSelectedDay-" + formatted_date).style.display = "block";


    var apptType = document.getElementById("appointmentType").innerHTML
    if (apptType == "New Patient Exam &amp; Cleaning") {
      // document.getElementById("appointmentType").innerHTML = apptType + " Book A Time With A Hygienist"
      document.getElementById("sortedHygienistsAvailability").style.display = "block";
      document.getElementById("HygienistsSelectedDay-" + formatted_date).style.display = "block";
    }

    else {
      // document.getElementById("appointmentType").innerHTML = apptType + " Book A Time With A Dentist"
      document.getElementById("sortedDentistsAvailability").style.display = "block";
      document.getElementById("DentistsSelectedDay-" + formatted_date).style.display = "block";
    }






    // document.getElementById("HygienistsSelectedDay-" + formatted_date).style.display = "block";
    var locationId = document.getElementById("locationId").value
    $.ajax({
      url: "/selected_day",
      type: 'POST',
      data: {
        dates: selectedDay,
        location_id: locationId
      },
      success: function() {
      }
    })
  }

}
