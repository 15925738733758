// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]

  selectAvaibilityWindow(event) {
   // event.currentTarget.setAttribute("id", "selected")


   event.currentTarget.classList.toggle("selected");
  }

  saveAvailabilityWindows(event) {
    var provider_id = event.currentTarget.dataset.provider
    document.getElementById("alert").style.display = "none";
    document.getElementById("noticeBox").style.display = "none";
    var times, i;
    var availability = []
    times = document.querySelectorAll(".selected");
    for (i = 0; i < times.length; i++) {
      availability.push(times[i].dataset.time)
    }

    var day = document.getElementById("selectedAvailabilityDay").value

    if (availability.length == 0) {
      document.getElementById("alert").style.display = "block";
    }

    else {

    $.ajax({
      url: "/admin/save_dentist_availability",
      type: 'POST',
      data: {"times": availability, "day": day, "provider_id": provider_id},
      success: function() {
        document.getElementById("noticeBox").style.display = "block";
        console.log("success")
        for (i = 0; i < times.length; i++) {
          times[i].classList.remove('selected');
        }
        $("#dentistAvailabilityCard").load(" #dentistAvailabilityCard > *");
      }
    })
  }
  }

  deleteAvailableTimeSlot(event) {
    var provider_id = event.currentTarget.dataset.provider
    var deletedTime = event.currentTarget.dataset.time
    var day = event.currentTarget.dataset.day
    document.getElementById("timeSlotContainer" + "-" + deletedTime).style.display = "none"
    $.ajax({
      url: "/admin/delete_availability_timeslot",
      type: 'POST',
      data: {"time": deletedTime, "day": day, provider_id: provider_id},
      success: function() {
      }
    })
  }

  allDayAvailability(event) {
    var day = event.currentTarget.dataset.day
    var provider_id = event.currentTarget.dataset.provider
    $("#dentistAvailabilityCard").load(" #dentistAvailabilityCard > *");
    $.ajax({
      url: "/admin/set_all_day_availability",
      type: 'POST',
      data: {"day": day, "provider_id": provider_id},
      success: function() {
      }
    })

  }

  clearAvailability(event) {
    var provider_id = event.currentTarget.dataset.provider
    document.getElementById("dentistAvailabilityCard").style.display = "none"

    $.ajax({
      url: "/admin/clear_availability",
      type: 'POST',
      data: {"provider_id": provider_id},
      success: function() {
      }
    })
  }

  showTimesBasedOnDay(event) {
    $("[id^=dateToShow-]").hide();
    var dateToShow = document.getElementById("selectedAvailabilityDay").value
    var dateToShow = moment(dateToShow).format("dddd");
    var dateToShowIdContainer = document.getElementById("dateToShow-" + dateToShow)
    if (dateToShowIdContainer.style.display == "block") {
      dateToShowIdContainer.style.display = "none"
    }
    dateToShowIdContainer.style.display = "block"
  }

  closeDay(event) {
    var day = event.currentTarget.dataset.day
    var location_id = event.currentTarget.dataset.location

    $.ajax({
      url: "/locations/close_day",
      type: 'POST',
      data: {
        day: day,
        location_id: location_id
      }
      })


  }

  openDay(event) {
    var day = event.currentTarget.dataset.day
    var location_id = event.currentTarget.dataset.location

    $.ajax({
      url: "/locations/open_day",
      type: 'POST',
      data: {
        day: day,
        location_id: location_id
      }
      })
  }







}
