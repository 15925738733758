// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]

  // getPromo(event) {
  //   var selectedButtons = document.getElementsByClassName('selected');

  //   while(selectedButtons.length > 0){
  //     selectedButtons[0].classList.remove('selected');
  //   }
  //   var promo = event.currentTarget.dataset.promo
  //   // event.currentTarget.classList.add('selected')
  //   // document.getElementById("cardFooterButton-" + promo).style.display = "block"
  //   if (document.getElementById("unconfirmedCard-" + promo).style.display == "block" || document.getElementById("unconfirmedCard-" + promo).style.display == "") {
  //     document.getElementById("unconfirmedCard-" + promo).style.display = "none"
  //     document.getElementById("confirmedCard-" + promo).style.display = "block"
  //     document.getElementById("promoNextButton-" + promo).style.display = "block"
  //     document.getElementById("confirmedCard-" + promo).classList.add("selected-promo");
  //   } else {
  //     document.getElementById("confirmedCard-" + promo).style.display = "none"
  //     document.getElementById("unconfirmedCard-" + promo).style.display = "block"
  //   }

  //   window.promo = promo

  // }

  getComfortLevel(event) {
    var selectedButtons = document.getElementsByClassName('selected');

    while(selectedButtons.length > 0){
      selectedButtons[0].classList.remove('selected');
    }
    var comfortLevel = event.currentTarget.dataset.comfort
    event.currentTarget.classList.add('selected')
    document.getElementById("surveyButtonTwo").style.display = "block"

    window.comfortLevel = comfortLevel
  }

  getImportance(event) {

    var selectedButtons = document.getElementsByClassName('selected');

    while(selectedButtons.length > 0){
      selectedButtons[0].classList.remove('selected');
    }

    var importance = event.currentTarget.dataset.importance
    event.currentTarget.classList.add('selected')
    document.getElementById("surveyButtonThree").style.display = "block"

    window.importance = importance

    $.ajax({
      url: "/survey_post",
      type: 'POST',
      data: {
        "survey_answers": {
        "promo": window.promo,
        "comfort": window.comfortLevel,
        "importance": window.importance
        }
      }
    })
  }


}
